/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";

// Overview page components
import Header from "layouts/profile/components/Header";
import PlatformSettings from "layouts/profile/components/PlatformSettings";

// Images
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";

function Overview() {
  const { id } = useParams();
  const [user, setUser] = useState({});
  const [listings, setListings] = useState([]);
  const sessionId = useSelector((state) => state.login.sessionId);
  useEffect(async () => {
    const response = await fetch(
      `https://manta.wholstersystems.com/admin/user/${id}?sessionId=${sessionId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          credentials: "same-origin",
        },
      }
    );
    const u = await response.json();
    setUser(u);
  }, []);

  useEffect(async () => {
    const response = await fetch(
      `https://manta.wholstersystems.com/listing/seller/${id}?sessionId=${sessionId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          credentials: "same-origin",
        },
      }
    );
    const u = await response.json();
    setListings(u);
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header user={user}>
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} xl={4}>
              <PlatformSettings userId={user.id} />
            </Grid>
            <Grid item xs={12} md={6} xl={4} sx={{ display: "flex" }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
              <ProfileInfoCard
                title="profile information"
                description={user.bio || ""}
                info={{
                  fullName: `${user.firstName} ${user.lastName}`,
                  mobile: `(${user?.phoneNumber?.areaCode || "xxx"}) ${
                    user?.phoneNumber?.exchange || "xxx"
                  }-${user?.phoneNumber?.extension || "xxxx"}`,
                  email: user.username,
                  website: user.website,
                  location: "USA",
                }}
                social={[]}
                action={{ route: "", tooltip: "Edit Profile" }}
                shadow={false}
              />
              <Divider orientation="vertical" sx={{ mx: 0 }} />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox pt={2} px={2} lineHeight={1.25}>
          <MDTypography variant="h6" fontWeight="medium">
            Listings
          </MDTypography>
          <MDBox mb={1}>
            <MDTypography variant="button" color="text">
              Listings for {user.firstName || ""}
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox p={2}>
          <Grid container spacing={6}>
            {listings.map((listing) => (
              <Grid item xs={12} md={6} xl={3}>
                <DefaultProjectCard
                  image={`https://manta.wholstersystems.com/images/listing/${listing.identifierKey}`}
                  label={`${listing.details.address.houseNumber} ${listing.details.address.street}`}
                  title={`$${Number(listing.details.cost).toLocaleString("en")}`}
                  description={listing.details.details}
                  action={{
                    type: "external",
                    route: `https://www.wholstersystems.com/listing/${listing.id}`,
                    color: "info",
                    label: "view listing",
                  }}
                  authors={[
                    { image: team1, name: "Elena Morison" },
                    { image: team2, name: "Ryan Milly" },
                    { image: team3, name: "Nick Daniel" },
                    { image: team4, name: "Peterson" },
                  ]}
                />
              </Grid>
            ))}
          </Grid>
        </MDBox>
      </Header>
    </DashboardLayout>
  );
}

export default Overview;
