/* eslint-disable import/prefer-default-export */
import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "features/login/loginSlice";
import { wholsterApi } from "services/wholster";

export const store = configureStore({
  reducer: {
    login: loginReducer,
    [wholsterApi.reducerPath]: wholsterApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(wholsterApi.middleware),
});
