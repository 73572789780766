import { Navigate, Outlet, useLocation } from "react-router-dom";

import { useSelector } from "react-redux";

function PrivateRoute() {
  const location = useLocation();
  const sessionId = useSelector((state) => state.login.sessionId);

  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  return sessionId ? <Outlet /> : <Navigate to="/authentication/sign-in" state={{ location }} />;
}

export default PrivateRoute;
