/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useSelector } from "react-redux";

import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

function PlatformSettings({ userId }) {
  const [enabled, setEnabled] = useState(false);
  const [verified, setVerified] = useState(false);
  const [password, setPassword] = useState("");
  const sessionId = useSelector((state) => state.login.sessionId);

  const getEnabled = async () => {
    const response = await fetch(
      `https://manta.wholstersystems.com/admin/user/${userId}/enabled?sessionId=${sessionId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          credentials: "same-origin",
        },
      }
    );
    const u = await response.json();
    setEnabled(u);
  };

  const putEnabled = async (value) => {
    const response = await fetch(
      `https://manta.wholstersystems.com/admin/user/${userId}/enabled/${value}?sessionId=${sessionId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          credentials: "same-origin",
        },
      }
    );
    await response.json();
    setEnabled(value);
  };

  const getVerified = async () => {
    const response = await fetch(
      `https://manta.wholstersystems.com/admin/user/${userId}/verified?sessionId=${sessionId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          credentials: "same-origin",
        },
      }
    );
    const u = await response.json();
    setVerified(u);
  };

  const putVerified = async (value) => {
    const response = await fetch(
      `https://manta.wholstersystems.com/admin/user/${userId}/verified/${value}?sessionId=${sessionId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          credentials: "same-origin",
        },
      }
    );
    await response.json();
    setVerified(value);
  };

  const changePassword = async () => {
    const response = await fetch(
      `https://manta.wholstersystems.com/admin/user/${userId}/password?sessionId=${sessionId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          credentials: "same-origin",
        },
        body: JSON.stringify({
          password,
        }),
      }
    );
    const u = await response.json();
    setVerified(u);
  };

  const onEnabledChanged = (value) => {
    putEnabled(value);
  };

  const onVerifiedChanged = (value) => {
    putVerified(value);
  };

  const handlePasswordChange = (e) => setPassword(e.target.value);

  if (userId) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      getEnabled();
      getVerified();
    }, []);
  }
  return (
    <Card sx={{ boxShadow: "none" }}>
      <MDBox p={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          platform settings
        </MDTypography>
      </MDBox>
      <MDBox pt={1} pb={2} px={2} lineHeight={1.25}>
        <MDTypography variant="caption" fontWeight="bold" color="text" textTransform="uppercase">
          account
        </MDTypography>
        <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
          <MDBox mt={0.5}>
            <Switch checked={enabled} onChange={() => onEnabledChanged(!enabled)} />
          </MDBox>
          <MDBox width="80%" ml={0.5}>
            <MDTypography variant="button" fontWeight="regular" color="text">
              Account is enabled
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
          <MDBox mt={0.5}>
            <Switch checked={verified} onChange={() => onVerifiedChanged(!verified)} />
          </MDBox>
          <MDBox width="80%" ml={0.5}>
            <MDTypography variant="button" fontWeight="regular" color="text">
              Email is verified
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox mt={3}>
          <MDTypography variant="caption" fontWeight="bold" color="text" textTransform="uppercase">
            password
          </MDTypography>
        </MDBox>
        <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
          <MDBox width="80%" ml={1.5}>
            <MDTypography variant="button" fontWeight="regular" color="text">
              Set Password
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox display="flex" alignItems="center" mb={0.5} ml={0.5}>
          <MDBox width="80%" ml={0.5}>
            <MDInput
              type="password"
              label="Password"
              value={password}
              onChange={handlePasswordChange}
              fullWidth
            />
          </MDBox>
        </MDBox>
        <MDBox display="flex" alignItems="center" mb={0.5} ml={0.5}>
          <MDBox width="80%" ml={0.5}>
            <MDButton variant="gradient" color="info" fullWidth onClick={() => changePassword()}>
              set password
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default props for the PlatformSettings
PlatformSettings.defaultProps = {
  userId: 0,
};

// Typechecking props for the PlatformSettings
PlatformSettings.propTypes = {
  userId: PropTypes.number,
};

export default PlatformSettings;
