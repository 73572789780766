/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

// Images
import logoXD from "assets/images/small-logos/logo-xd.svg";

import { Link } from "react-router-dom";

import FacebookIcon from "@mui/icons-material/Facebook";
import GoogleIcon from "@mui/icons-material/Google";
import CheckIcon from "@mui/icons-material/Check";
import AppleIcon from "@mui/icons-material/Apple";

export default function data(userArray) {
  const User = ({ image, name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDTypography variant="button" fontWeight="medium" ml={1} lineHeight={1}>
        {name}
      </MDTypography>
    </MDBox>
  );

  return {
    columns: [
      { Header: "user", accessor: "user", width: "25%", align: "left" },
      { Header: "email", accessor: "email", width: "25%", align: "left" },
      { Header: "viewed", accessor: "viewed", align: "left" },
      { Header: "", accessor: "integrations", align: "left" },
      { Header: "notifications", accessor: "notifications", align: "left" },
      { Header: "enabled", accessor: "enabled", align: "left" },
    ],

    rows: userArray.map((user) => ({
      user: <User image={logoXD} name={`${user.firstName} ${user.lastName}`} />,
      email: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          <Link to={`/profile/${user.id}`}>{user.username}</Link>
        </MDTypography>
      ),
      integrations: (
        <MDBox display="flex" py={1}>
          {user.integrations.APPLE && <AppleIcon />}
          {user.integrations.FACEBOOK && <FacebookIcon />}
          {user.integrations.GOOGLE && <GoogleIcon />}
        </MDBox>
      ),
      viewed: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {user.viewed}
        </MDTypography>
      ),
      notifications: (
        <MDBox width="8rem" textAlign="left">
          {user.expoToken && <CheckIcon />}
        </MDBox>
      ),
      enabled: (
        <MDBox width="8rem" textAlign="left">
          {user.enabled && <CheckIcon />}
        </MDBox>
      ),
    })),
  };
}
