/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";

function Dashboard() {
  const [logins, setLogins] = useState({});
  const [listings, setListings] = useState({});
  const [favorites, setFavorites] = useState({});
  const [metrics, setMetrics] = useState({});
  const sessionId = useSelector((state) => state.login.sessionId);

  useEffect(async () => {
    const response = await fetch(
      `https://manta.wholstersystems.com/admin/logins?sessionId=${sessionId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          credentials: "same-origin",
        },
      }
    );
    const u = await response.json();
    const kvp = Object.entries(u).sort((a, b) => a[0] - b[0]);
    const loginMap = {
      labels: kvp.map((k) => k[0]),
      datasets: { label: "Day", data: kvp.map((p) => p[1]) },
    };
    setLogins(loginMap);
  }, []);

  useEffect(async () => {
    const response = await fetch(
      `https://manta.wholstersystems.com/admin/listings?sessionId=${sessionId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          credentials: "same-origin",
        },
      }
    );
    const u = await response.json();
    const kvp = Object.entries(u).sort((a, b) => a[0] - b[0]);
    const listingMap = {
      labels: kvp.map((k) => k[0]),
      datasets: { label: "Day", data: kvp.map((p) => p[1]) },
    };
    setListings(listingMap);
  }, []);

  useEffect(async () => {
    const response = await fetch(
      `https://manta.wholstersystems.com/admin/favorites?sessionId=${sessionId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          credentials: "same-origin",
        },
      }
    );
    const u = await response.json();
    const kvp = Object.entries(u).sort((a, b) => a[0] - b[0]);
    const favoritesMap = {
      labels: kvp.map((k) => k[0]),
      datasets: { label: "Day", data: kvp.map((p) => p[1]) },
    };
    setFavorites(favoritesMap);
  }, []);

  useEffect(async () => {
    const response = await fetch(
      `https://manta.wholstersystems.com/listing/metrics?sessionId=${sessionId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          credentials: "same-origin",
        },
      }
    );
    const u = await response.json();
    setMetrics(u);
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="weekend"
                title="Offers"
                count={metrics.Offers || 0}
                percentage={{
                  color: "success",
                  label: "Total offers submitted",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="person_add"
                title="Users"
                count={metrics.Users || 0}
                percentage={{
                  color: "success",
                  label: "Total users",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="store"
                title="Searches"
                count={metrics["Saved Searches"] || 0}
                percentage={{
                  color: "success",
                  label: "Filters saved",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="leaderboard"
                title="Listings"
                count={metrics.Listings || 0}
                percentage={{
                  color: "success",
                  label: "Listings created",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="info"
                  title="listing views"
                  description="Properties viewed by users"
                  date="just updated"
                  chart={favorites}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="success"
                  title="daily listings"
                  description="Listings created this week"
                  date="just updated"
                  chart={listings}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="dark"
                  title="user logins"
                  description="Users logged in this week"
                  date="just updated"
                  chart={logins}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={8}>
              <Projects />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <OrdersOverview />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default Dashboard;
