/* eslint-disable react/no-array-index-key */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

// Material Dashboard 2 React example components
import TimelineItem from "examples/Timeline/TimelineItem";

function OrdersOverview() {
  const [recents, setRecents] = useState([]);
  const sessionId = useSelector((state) => state.login.sessionId);
  useEffect(async () => {
    const response = await fetch(
      `https://manta.wholstersystems.com/admin/recentListings?sessionId=${sessionId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          credentials: "same-origin",
        },
      }
    );
    const u = await response.json();
    setRecents(u);
  }, []);
  return (
    <Card sx={{ height: "100%" }}>
      <MDBox pt={3} px={3}>
        <MDTypography variant="h6" fontWeight="medium">
          Recent Listings
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        {recents.map((listing, i) => (
          <TimelineItem
            onClick={() => window.open(`https://www.wholstersystems.com/listing/${listing.id}`)}
            key={`listing_${i}`}
            color="success"
            icon="notifications"
            description={listing.Seller}
            title={listing.Address}
            dateTime={listing.Date}
          />
        ))}
      </MDBox>
    </Card>
  );
}

export default OrdersOverview;
